import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
// import { HTMLContent } from '../components/Content';
import Content, { HTMLContent } from '../components/Content';
// import Pricing from '../components/Pricing';
import { Header } from '../components/Header';

import { CartContextConsumer } from '../contexts/CartContext';
import { ProductsContextConsumer } from '../contexts/ProductsContext';

const CenteredDiv = ({ children }) => {
  return (<div className="has-text-centered is-centered">{children}</div>);
};

const VariantDropDown = ({ variants, initialValue = '', onchange }) => (
      <div className="control">
        <div className="select">
          <select id="variants" name="variants" onChange={onchange}>
            { variants.map((variant) => (
              <option key={variant.code} value={variant.code} selected={variant.code === initialValue}>{variant.name}</option>
            )) }
          </select>
        </div>
      </div>
);

const VariantImage = ({ title, variant }) => {
  // console.log(`variant: ${variant}`);
  console.dir(variant);
  if ('image' in variant) {
    const vImage = variant.image;
    if (vImage === null) return null;
    return (<img style={ { width: "10em" } } alt={title} src={(typeof vImage !== 'string' && 'childImageSharp' in vImage) ? vImage.childImageSharp.fluid.src : vImage } />);
  }
  return null;
};

const CartProductCounter = ({ sku, variant }) => (  
  <ProductsContextConsumer>
    {products => {
      // console.dir(products);
      const product = products[sku];
      return (
        <CartContextConsumer>
          {cart => {
            // console.dir(cart);
            const vData = product.getVariant(variant);
            // console.log(`sku: ${sku} v: ${variant}`)
            // console.dir(vData);
            if (vData.instock === false) return (<span>Sorry the {vData.name} style is out of stock.</span>);
            const itemCount = cart.getItemCount(sku, variant);
            if (itemCount === 0) return null;
            return (<span>{itemCount} in cart</span>)
          }}
        </CartContextConsumer>
      );
    }}
  </ProductsContextConsumer>
);

export class ProductPageTemplate extends React.Component {
  // static contextType = CartContext;
  state = {
    selectedVariant: '',
    buttonDisabled: false
  }

  constructor(props) {
    super(props);
    // console.dir(this.props);
    this.variants = this.props.variants;
    // this.state.selectedVariant = this.props.details.variants.toArray()[0].get('code');
    // console.dir(this.props.variants);
    this.state.selectedVariant = this.props.variants.find((val) => {
      return val.instock === true;
    }).code; 
    this.state.initialValue = this.state.selectedVariant;
    // console.log(`init Val: ${this.state.initialValue}`)
    /* if (this.variantInStock(this.state.selectedVariant)) {
      this.state.buttonDisabled = false

    } else this.state.buttonDisabled = true; */
  }

  getVariant(variantCode) {
    // console.log(`ProductPage.class.getVariant ${variantCode}`);
    return this.variants.find((val) => val.code === variantCode);
    // return 'unknown';
  }

  variantInStock(variantCode) {
    return this.getVariant(variantCode).instock;
  }

  setButtonDisabled(disabled) {
    this.setState({...this.state, buttonDisabled: disabled});
  }

  setVariant(event) {
    console.dir(event.target.value);
    
    // const variant = document.getElementById('variants').value;
    const vData = this.getVariant(event.target.value);
    this.setState({...this.state, selectedVariant: event.target.value, buttonDisabled: (vData.instock === false) });
    // */
  }

  onButtonClick(cart) {
    cart.addItem(this.props.sku, this.state.selectedVariant);
  }

  onClickB(event) {
    this.setButtonDisabled(!this.state.buttonDisabled);
  }

  render() {

    const { image, title, heading, subheading, content, sku, price, contentComponent } = this.props;
    const PageContent = contentComponent || Content;
    // console.log('ProductPage Props:-');
    // console.dir(this.props);
    // const variantsArray = details.variants;

    return (
      <div className="content">
        <Header title={heading} subheading="" image="/img/kailash-three-bottles.webp" />
        <section className="section section--gradient">
          <div className="container">
            <div className="section">
              <div className="columns">
                <div className="column is-7 is-offset-1">
                  <h3 className="has-text-weight-semibold is-size-2">{subheading}</h3>
                </div>
              </div>
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="columns">
                    <div className="column is-one-quarter"><img style={ { width: "10em" } } alt={title} src={('childImageSharp' in image) ? image.childImageSharp.fluid.src : image } /></div>
                    <div className="column is-one-half"><PageContent content={content} /></div>
                    <div className="column is-one-quarter"><VariantImage title={heading} variant={this.getVariant(this.state.selectedVariant)} /></div>
                  </div>
                  <ProductsContextConsumer>
                  {products => {
                  // const prodObject = products[details.sku];
                  return (
                  <CartContextConsumer>
                  {cart => (
                    <div className="columns" style={ { marginTop: '1em', width: '100%' }}>
                      <div className="column is-one-third">
                        <CenteredDiv>£{price}</CenteredDiv>
                      </div>
                      <div className="column is-one-third">
                        <CenteredDiv><VariantDropDown initialValue={this.state.initialValue} variants={this.props.variants} onchange={this.setVariant.bind(this)} /></CenteredDiv>
                      </div>
                      <div className="column is-one-third">
                      <CenteredDiv><button className="button"
                          onClick={this.onButtonClick.bind(this, cart)}
                          aria-label={`Add ${title} to your cart`}
                          disabled={this.state.buttonDisabled}
                        >
                          Add to cart
                        </button></CenteredDiv>
                        <CenteredDiv><CartProductCounter sku={sku} variant={this.state.selectedVariant} /></CenteredDiv>
                      </div>
                    </div>
                  )}
                  </CartContextConsumer>
                  )}
                  }
                  </ProductsContextConsumer>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      );

  }
}

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  weight: PropTypes.number,
  price: PropTypes.string,
  sku: PropTypes.string,
  variants: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    instock: PropTypes.bool,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }))
};

const ProductPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark

  return (
    <Layout>
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        content={html}
        contentComponent={HTMLContent}
        price={parseFloat(frontmatter.price)}
        weight={frontmatter.weight}
        sku={frontmatter.sku}
        variants={frontmatter.variants}
      />
    </Layout>
  )
}

ProductPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ProductPage

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        order
        price
        weight
        volume
        sku
        variants {
          name
          code
          instock
          order
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      html
    }
  }
`
